<template>
  <div class="form" id="forms2">
    <div class="form_con">
      <h2>{{info?info.title:''}}</h2>
      <div class="desc">
      <!-- <p>姓名：{{info?info.name:''}}</p>
      <p>手机：{{info?info.mobile:''}}</p>
      <p>邮箱：{{info?info.email:''}}</p>
      <p>地址：{{info?info.address:''}}</p> -->
      <p>参赛主题：{{info?info.theme:''}}</p>
      <p>设计说明：{{info?info.introduce:''}}</p>
      </div>
      
      <div class="detail">
        <div v-if="info">
          <div v-for="(item,index) of works" :key="index">
            
            <video  :src="item.url" v-if="item.type=='mp4'" controls></video>
            <img :src="item.url" v-else />
          </div>
          
          </div>
        
      </div>
     </div>
  </div>
</template>
<script>
export default {
  name: "matchDetail",
  data() {
    return {
      id:'',
      info:null,
      works:[]
    };
  },
  components:{
  },
  mounted() {
    this.id=this.$route.query.id;
    this.getInfo();
    setTimeout(()=>{
      var odiv = document.getElementById('forms2');
    let s =this.getDistanceOfLeft(odiv).top;
    document.body.scrollTop = s;
    document.documentElement.scrollTop=s;
    },10)
  },
  methods: {
    getInfo() {
      this.$api.POST(this.$face.events_works_info, { id: this.id }, (res) => {
        if(res.code==200){
          this.info=res.data;
          let obj={};
          for(let i of this.info.works){
            let geshi=i.split(".")[3];
            obj={
              type:geshi,
              url:i
            }
            this.works.push(obj);
           
          }
          
        }
      });
    },
    
    getDistanceOfLeft(obj) {
      var left = 0;
      var top = 0;
      while (obj) {
        left += obj.offsetLeft;
        top += obj.offsetTop;
        obj = obj.offsetParent;
      }
      return {
        left: left,
        top: top,
      };
    }
  },
};
</script>

<style scoped>
.form {
  background: #0000fe;
  overflow: hidden;
}
.form_con {
  width: 1255px;
  background: #fff;
  border-radius: 20px;
  box-sizing: border-box;
  padding: 35px 45px;
  margin: 105px auto;
  min-height: 800px;
}
.form_con h2 {
  font-size: 38px;
  height: 80px;
  line-height: 80px;
  border-bottom: 1px #bfbfbf solid;
}
.form_con {
  overflow: hidden;
}
.list{ padding-top: 30px;}
.list li{ width: 365px; height: 340px; float: left; margin:0 11px 22px; overflow: hidden;}
.list li .thumb{ width: 365px; height: 270px; position: relative; background: #f2f2f2; border-radius: 6px; position: relative; overflow: hidden; border-radius: 10px;}
.list li .thumb .thumb_img{position: absolute; top: 50%; transform: translateY(-50%); width: 100%;}
.list li .thumb .thumb_img img{ width: 100%; transition: 0.3s;}
.list li .title{ font-size: 20px; margin-top: 25px; color: #333333;}
.list li .thumb .del,.list li .thumb .edit{ width: 60px; height: 32px; font-size: 16px; color: #fff; text-align: center; line-height: 32px; background: rgba(0,0,0,0.3); border-radius: 6px; display: none; cursor: pointer;}
.list li .thumb .del{ position: absolute; left: 12px; top: 12px;}
.list li .thumb .edit{ position: absolute; right: 12px; top: 12px;}
.list li:hover .edit,.list li:hover .del{ display: block;}
.list li:hover .thumb .thumb_img img{transform:scale(1.05);}
.detail{ text-align: center; margin-top: 30px;}
.detail img{max-width: 100%; margin:20px auto 0;}
.desc{ font-size: 14px; color: #999; margin-top: 30px; line-height: 30px;}
.desc p{	white-space: pre-wrap;
word-break: break-all;}
.detail video{ max-width: 100%;}
</style>